import { Injectable } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import '@angular/localize/init';


export enum Locales{
	en = 'English',
	de = 'Deutsch'
}

@Injectable({
	providedIn: 'root'
})
export class TranslationProviderService {

	constructor(){}

	public async getFile(url: string){
		const result = await fetch(url);
		const content = await result.text();
		return content;
	}

    public getLanguage(){
		const result = localStorage.getItem('settings');
		const content = result ? JSON.parse(result) : null;
		return content?.language ?? 'de';
    }

	public async getTranslation(lang : string = null){
		lang = lang ?? this.getLanguage();

		let fileExt = `${lang}.xlf`;

		if(!Locales[lang]){
			return null;
		}

		const xml = await this.getFile(`assets/i18n/messages.${fileExt}`);
		const translation = this.xlfToJson(this.parseXml(xml));

		return translation;
	}

	public xlfToJson(xml : Document){
		const body = xml.getElementsByTagName('body')[0].children;
		const json = {};
		for(let i = 0; i < body.length; i++){
			const key = body[i].id;
			const lang1 = body[i].getElementsByTagName('source')?.[0]?.textContent?.trim();
			const value = body[i].getElementsByTagName('target')?.[0]?.textContent?.trim() ?? lang1;
			json[key] = value;
		}
		return json;
	}

	public parseXml(xmlStr : string){
		const parser = new DOMParser();
    	const xmlDoc = parser.parseFromString(xmlStr, 'text/xml');
		return xmlDoc;
	}

	public async change(code : string = 'de'){
		const translation = await this.getTranslation(code);
		if(translation){
			loadTranslations(translation);
			if(document && document?.location){
				//document.location.reload();
			}
		}
	}
}
